import React, { useEffect, useRef, useState } from "react";
import { getRestCountries, validatePhoneNumbers } from "../../utils/envConfig";

export default function InputContact ( {
    onSelectCountry = ( selectedCountry ) => { },
    inputProps = { onBlur: ( e ) => { } },
    countriesContainerClasses,
    containerClasses,
    country = {
        name: "United Arab Emirates",
        code: "+971",
        short: "UAE",
        image: "https://flagcdn.com/w320/ae.png",
        phone: "",
        cca2: "AE",
    },
    onInputChange = ( value = "" ) => { },
    countryClasses,
    searchClasses,
    countryItemClasses,
    onError = ( error = "" ) => { },
} )
{
    const inputRef = useRef( null );
    const containerRef = useRef( null );
    const showCountriesRef = useRef( null );
    const testRef = useRef( null );

    const [ showingCountries, setShowingCountrie ] = useState( false );

    const [ countries, setCountries ] = useState( [] );

    const [ loading, setLoading ] = useState( false );

    const [ search, setSearch ] = useState( "" );



    const filteredCountries = countries?.filter( ( country ) =>
    {
        return country?.name?.toLowerCase()?.includes( search?.toLowerCase() );
    } );

    useEffect( () =>
    {
        document.addEventListener( "mousedown", handleClickOutside );
        // getCountries();
        return () =>
            document.removeEventListener( "mousedown", handleClickOutside );
    }, [] );

    const showCountries = async () =>
    {
        try
        {
            if ( !countries.length )
            {
                setLoading( true );
                const countries = await getRestCountries();
                setCountries( countries );
            }

            showCountriesRef.current?.classList?.add(
                "!overflow-y-auto",
                "!h-[300px]",
                "!border"
            );
        } catch ( err )
        {
            console.log( err );
        } finally
        {
            setLoading( false );
            setShowingCountrie( true );
        }
    };

    const hideCountries = () =>
    {
        showCountriesRef.current?.classList?.remove(
            "!overflow-y-auto",
            "!h-[300px]",
            "!border"
        );
        setShowingCountrie( false );
    };

    const handleClickOutside = ( e ) =>
    {
        const { current: wrap } = showCountriesRef;
        if (
            wrap &&
            !wrap.contains( e.target ) &&
            !testRef.current?.contains( e.target )
        )
        {
            hideCountries();
        }
    };

    const onBlur = ( e ) =>
    {
        const { value } = e?.target;

        if ( inputProps.required && !value.length )
        {
            onError( "Phone number is required" );
            return;
        }

        const validNumber = validatePhoneNumbers( {
            code: country?.code,
            phone: e.target.value,
            cca2: country?.cca2,
        } );

        if ( inputProps.required )
        {
            onError( validNumber ? "" : "Invalid number for selected country" );
        } else
        {
            onError( "" );
        }

        if ( inputProps.onBlur )
        {
            inputProps.onBlur( e );
        }

        containerRef.current?.classList?.remove( "!border-2" );
    };

    return (
        <div className="relative ">
            <div
                className={ `flex gap1 items-center rounded-full  border overflow-hidden  ${ containerClasses }` }
                ref={ containerRef }
            >
                <div
                    className={ `flex items-center gap-x-0.5 px-1 cursor-pointer ${ countryClasses }` }
                    onClick={ () =>
                        !showingCountries ? showCountries() : hideCountries()
                    }
                    ref={ testRef }
                >
                    <img
                        src={ country?.image }
                        width={ 20 }
                        height={ 15 }
                        objectfit="fill"
                        alt={ country?.name }
                    />

                    <span className="text-base">{ country?.code }</span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                        className="w-4 h-4 my-auto"
                        fill="currentColor"
                    >
                        <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" />
                    </svg>
                </div>
                <div
                    className={ `bg-white absolute left-0 top-[105%] z-10  border-0 h-0 overflow-y-hidden border-blueGray-400 overflow-x-auto max-h-[300px] rounded-md show-scroll transition-all duration-300 ease-linear text-black ${ countriesContainerClasses }` }
                    ref={ showCountriesRef }
                >
                    <input
                        type="text"
                        name="search"
                        value={ search }
                        onChange={ ( e ) => setSearch( e.target.value ) }
                        className={ `w-full border-none ${ searchClasses }` }
                        placeholder="Search country"
                    />
                    { loading ? (
                        <svg
                            role="status"
                            className="inline mx-1 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-red-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                            />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                            />
                        </svg>
                    ) : filteredCountries?.length ? (
                        filteredCountries?.map( ( countryData ) => (
                            <div
                                key={ countryData?.code + countryData?.cca2 }
                                className={ `p-2 border-t border-blueGray-300 cursor-pointer hover:bg-blueGray-200 ${ countryItemClasses }` }
                                onClick={ () =>
                                {
                                    hideCountries();
                                    onSelectCountry( countryData );
                                    setSearch( "" );

                                    // setSelectedCountry(countryData);

                                    inputRef?.current?.focus();
                                } }
                            >
                                { countryData?.name } ({ countryData?.code })
                            </div>
                        ) )
                    ) : (
                        <div>No data to show</div>
                    ) }
                </div>
                <input
                    ref={ inputRef }
                    { ...inputProps }
                    onChange={ ( e ) =>
                    {
                        const { value } = e.target;

                        onInputChange( value );

                        if ( inputProps.onChange )
                        {
                            inputProps.onChange( e );
                        }
                    } }
                    required={ inputProps?.required }
                    placeholder="Your Phone Number"
                    onBlur={ onBlur }
                    onFocus={ ( e ) =>
                    {

                        if ( inputProps.onFocus )
                        {
                            inputProps?.onFocus( e );
                        }
                    } }
                    className={ `flex-1 appearance-none border-none  px-3 outline-none focus:ring-0 bg-transparent ${ inputProps?.className }` }
                />
            </div>
        </div>
    );
}
